(function ($) {
  Drupal.behaviors.ELC_pageUtilites = {
    attach: function (context, settings) {
      $('.page-utilities__signup .form-submit', context).click(function () {
	  var dest = $(this).attr('data-dest');

	  dest = dest || '';
        if (/^\/.+$/.test(dest)) {
          dest = dest.replace(/^(\s|\/)+/, '');
        }
        if (/^(?!http|\/).+$/.test(dest)) {
          dest = Drupal.settings.basePath + dest;
        }
        var email = document.getElementsByName('search');
        var emailvalue = email[0].value;

        if (emailvalue != $('#default').val() && emailvalue != '') {
          dest = dest + '?email=' + escape(emailvalue);
          $('.errormessage').addClass('hidden');
          window.location.href = dest;
        } else {
          $('.offers-email-signup-form__errors').removeClass('hidden');
        }

        return false;
      });
    }
  };
})(jQuery);
